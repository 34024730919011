<template>
  <div>
    <list-template
        :current-page="page"
        :is-check-input-box="false"
        :loading="loading"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
        @onSearch="onSearch"
    ></list-template>
  </div>
</template>
<script>
import {mapState} from "vuex";
import ListTemplate from "@/components/pages/List.vue"
import {getStudentStatus} from "@/api/common"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {ListTemplate},
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [{
        prop: 'approve_status',
        tag: "select",
        placeholder: '筛选审批状态',
        options: [{
          label: "待审批",
          value: 0
        }, {
          label: "已审批",
          value: 1
        }],
      }, {
        prop: 'school_id',
        tag: "select",
        placeholder: '筛选报名学校',
        options: [],
        label: "school_name",
        value: "id"
      },
      // 退费新增字段
      {
        prop:"student_status",
        tag: 'select',
        placeholder:"筛选学生状态",
        options:[]
      },
      {
        prop: "student_name",
        placeholder: "搜索学生姓名",
      }, {
        prop: "name",
        placeholder: "搜索家长姓名",
      }],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig: [
        {
          prop: "approve_status_name",
          label: "审批状态",
          render: row => `<div class="text-${row.approve_status == 1 ? 'success' : 'danger'}">${row.approve_status_name}</div>`
        },
        {prop: "student_status_cn", label: "学生状态"},
        {prop: "student_name", label: "学生姓名"},
        {prop: "name", label: "家长姓名"},
        {prop: "school_name", label: "报名学校"},
        {prop: "student_type_name", label: "学生类型"},
        {
          prop: "handle",
          label: "操作",
          width: "120rem",
          handle: true,
          render(row) {
            if (row.approve_status == 1) return ["查看"]
            else return ["审批"]
          }
        },
      ],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.$_register('api/recruit/common/consulting-school').then(res => {
      this.searchConfig[1].options = res.data.data.entity_school || []
    })
    getStudentStatus().then(res=>{
      this.searchConfig[2].options = res.data.data;
    })
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    getData() {
      this.loading = true
      let params = {...this.search, page: this.page}
      this.tableData = [];
      this.$_register('api/recruit-v2/approve/get-list', {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    handleEdit(row, text) {
      if (text.type === 'view') this.$router.push('./look?id=' + row.id)
      else this.$router.push('./edit?id=' + row.id)
    },

    handleCurrentChange(val) {
      this.$store.commit("setPage", val);
      this.getData()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .text-success {
  color: #70b603
}

::v-deep .text-danger {
  color: #f59a23;
}
</style>
